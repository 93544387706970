<template>
  <div class="top-box">
    <img
      :src="'https://www.pangodgroup.com/static/' + themeLogo + '.png'"
      alt="网站logo"
      class="page-logo"
    />
    <div class="langs">
      <div
        :class="{ lang: true, active: nowLang == item.value }"
        v-for="item in languages"
        :key="item.value"
        @click="langChange(item.value)"
      >
        {{ item.label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      defaultLang: "zh",
      languages: [
        { label: "中文", value: "zh" },
        { label: "English", value: "en" },
        { label: "日本語", value: "ja" },
      ],
    };
  },
  props: {
    theme: {
      type: String,
      default: "",
    },
  },
  computed: {
    nowLang() {
      return this.$i18n.locale;
    },
    themeLogo() {
      return this.theme == "" ? "logo" : "logo-white";
    },
  },
  methods: {
    // 切换语言
    langChange(value) {
      this.$i18n.locale = value;
      localStorage.setItem("lang", value);
      this.$emit("change");
    },
  },
};
</script>

<style lang="scss" scoped>
.page-logo {
  width: 200px;
  position: absolute;
  left: 2%;
  top: 2%;
}
.langs {
  display: flex;
  align-items: center;
  position: absolute;
  top: calc(2% + 13px);
  right: 6%;
  z-index: 20;

  .lang {
    width: 70px;
    text-align: center;
    padding: 1px 0;
    cursor: pointer;

    & + .lang {
      border-left: 1px solid;
    }

    &:hover {
      font-weight: bold;
      color: #e3792a;
    }
  }
  .active {
    font-weight: bold;
    color: #e3792a;
  }
}
</style>
