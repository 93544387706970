export const message = {
  lang: "zh",
  action: {
    cancel: "取消",
  },
  sideMap: {
    map1: "首页",
    map2: "走进盘古纵横",
    map3: "集团概况",
    map4: "实力见证",
    map5: "业务版图",
    map6: "企业文化",
    map7: "合作伙伴",
    map8: "联系我们",
  },
  banner: {
    title: "聚焦商业服务领域",
    sub: "集企业自主经营与投资孵化于一体",
  },
  box: {
    introTitle: "走进盘古纵横",
    introDesc:
      "成都盘古纵横集团，源于2003年成立的成都盘古网络有限公司，聚焦于商业服务领域，是一家集企业自主经营与投资孵化于一体的综合型集团公司。",
    introMore: "了解详情",
    viewAll: "显示全部",
    generalTitle: "集团概况",
    generalDesc:
      "成都盘古纵横集团，源于2003年成立的成都盘古网络有限公司，聚焦于商业服务领域，是一家集企业自主经营与投资孵化于一体的综合型集团公司，目前在四川、重庆、甘肃、山西、广西、北京、日本等地均设有分支机构。主营业务涵盖广告及技术服务、金融典当服务、酒旅服务等，先后孵化了盘古广告、盘古信息、福都典当、盘古日本、寰纵国旅等业务和服务品牌。21年以来，集团先后为成都市人民政府、成都市人才服务中心、成都市市场监督管理局、成都市气象局、西南油气田公司、中国五冶集团公司、成都市软件产业发展中心等提供过广告和技术服务， 为中国工程物理研究院、绵阳南山中学、成都京东方医院等企事业单位提供内容服务，为中国银行、民生银行、邮储银行等长期提供品牌宣传服务，为大量中小企业提供短期应急金融服务，并于2022年开始开展日本民宿运营管理、国内游/出境游、企业/团体定制游、移民留学等服务，在业界及客户中建立了良好的口碑和知名度。",
    unit: "年",
    strengthTitle: "实力见证",
    strength1Label: "从业经验",
    strength1Desc:
      "腾讯、百度、小红书、超级汇川、巨量引擎等十余种线上广告服务及分众、门禁道闸、随心推等线下广告服务、技术服务、内容服务、金融典当服务、酒旅服务等业务",
    strength2Label: "服务团队",
    strength2Desc:
      "为广大客户提供专业的互联网广告及技术服务、内容服务、金融典当服务、酒旅服务",
    strength3Label: "甲级写字楼",
    strength3Desc:
      "四川、重庆、甘肃、山西、广西、北京、日本，两国六省各地，地处城市最中心、纯苹果现代化办公",
    strength4Label: "合作客户",
    strength4Desc:
      "行业涉及政府、教育、能源、制造、零售、电子商务、医疗、餐饮、物流、旅游、摄影、装修、家居、美容、化工等，分布广泛",
    cooperationTitle: "合作伙伴",
  },
  business: {
    title: "业务版图",
    more: "了解详情",
    pangu: {
      title: "广告业务",
      info1: "搜索广告",
      info2: "信息流广告",
      info3: "线下广告",
      info4: "电商服务",
      info5: "技术服务",
      info6: "内容服务",
      info7: "品牌服务",
      desc: "盘古广告，互联网广告及技术专业服务商。自2003年成立以来，基于丰富的媒体平台资源、强大的直销管理能力、丰富的行业经验及良好的服务意识与能力，盘古广告旨在打造一个链接品类繁多、发展迅猛的互联网媒体与区域各行业客户之间的服务平台，帮助各类互联网媒体平台实现其广告在区域市场内的推广和变现，帮助区域市场客户实现其传统业务模式与网络营销的有效结合，在新的竞争环境下获取独特优势并提高回报率。多年来盘古广告在客户及业界中建立了良好的口碑和知名度。",
    },
    fangfa: {
      title: "技术服务",
      info1: "大客户数字化定制",
      info2: "街道/社区SaaS服务",
      info3: "乡村振兴IoT服务商",
      desc: "方法数码，创新型数字技术服务提供商。公司成立于2003年，为企事业单位提供专业的软件开发服务，系统集成服务及电子商务服务。一直深耕教育、能源、制造、政府、零售、电子商务等重点领域，持续为客户提供专业、全面、高效的软件技术服务。方法数码是获得软件企业认定证书和软件产品登记证书的双软认证企业，是获得ISO9001质量管理体系认证、ISO14001环境管理体系认证、OHSAS18001职业健康安全管理体系认证的企业，是获得电信增值业务经营许可证(ICP)的专业化服务商，也是四川省电子商务协会及成都软件行业协会常务理事单位。",
    },
    diandang: {
      title: "金融服务",
      info1: "不动产抵押典当",
      info2: "财产权利质押典当",
      info3: "动产质押典当等",
      desc: "四川福都典当有限公司成立于2009年，注册资本8000万元，是经国家金融监督管理总局四川监管局批准取得《典当经营许可证》的可开展各类质押、抵押贷款业务的非银行类金融机构。主营业务包括动产质押典当、财产权利质押典当、房地产抵押典当等。本着“诚信为本，客户至上”的经营理念，奉行“服务为先，诚信立本，缓人之急”的服务宗旨，秉承“敬业、守信、忠诚、奉献、开拓、创新、立志、图强”的企业精神，为广大客户提供“灵活、便捷、短期”的融资服务，致力于把福都典当打造为中小企业、广大百姓可信赖的“贷款银行”。",
    },
    hongjun: {
      title: "品牌服务",
      info1: "品牌管理",
      info2: "视频拍摄",
      info3: "形象策划",
      info4: "公关活动",
      desc: "盘古广告致力于帮助政府及企业客户进行品牌管理、形象策划、宣传咨询、公关活动，同时提供广告设计、制作、跨媒体投放、活动执行、软件开发等服务。",
      desc_back:
        "成都鸿钧品牌管理有限公司致力于帮助政府及企业客户进行品牌管理、形象策划、宣传咨询、公关活动，同时提供广告设计、制作、跨媒体投放、活动执行、软件开发等服务。",
    },
    riben: {
      title: "国际业务",
      info1: "民宿运营",
      info2: "酒店管理",
      info3: "赴日旅居",
      desc: "盤古日本株式会社，2020年成立于日本东京。从中国到日本，从日本到中国，专注跨境旅游与对日投资等服务。以东京都市圈为核心，服务辐射日本全境，以独到的眼光探寻高品质房源，打造关东地区民宿酒店品牌标杆。为旅日游客提供典雅、舒适居所，为高净值人群提供理想投资标的，为跨境商务客户提供优质咨询服务。",
    },
    huanzong: {
      title: "旅游服务",
      info1: "国内游/出境游",
      info2: "企业/团体定制游",
      info3: "全球签证代办",
      info4: "机酒包车代订",
      info5: "移民留学服务",
      desc: "成都寰宇纵横国际旅行社有限公司（简称寰纵国旅），成立于2019年，总部位于四川省成都市，是一家集线路定制、攻略服务、全球签证代办、酒店机票代订、移民留学等服务于一体的全链路综合旅游服务商，业务范围涵盖全国，可承接个人、团体旅游及企业商务考察、会议等相关业务。",
    },
    hrefTip: "进入官网",
    noHrefTip: "敬请期待",
  },
  mission: {
    mission1Title: "使命",
    mission1desc1: "助力客户事业发展",
    mission1desc2: "成就员工人生价值",
    mission2Title: "愿景",
    mission2desc1: "成为一家追求卓越的商业服务领域综合型企业",
    mission2desc2: "成为一家勇于承担社会责任并有人情味的企业",
    mission3Title: "价值观",
    mission3desc1: "正直诚信 敬业乐群",
    mission3desc2: "悦纳变化 令行禁止",
    mission3desc3: "只做第一 使命必达",
  },
  contact: {
    title: "联系我们",
    addrTitle: "地址",
    addrDesc: "四川省成都市东大路318号IMP环球都汇广场37F",
    phoneTitle: "电话",
    emailTitle: "邮箱",
  },
  bottom: {
    copyText: "©2024 成都盘古纵横集团有限公司 版权所有",
    icp: "备案号",
  },
};
