<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  created() {
    this.redirectMP();
    window.addEventListener("resize", this.redirectMP);
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return !!flag;
    },
    // 电脑、手机端重定向
    redirectMP() {
      if (window.location.href.indexOf("/m") != -1) {
        // 手机端
        if (!this._isMobile()) {
          this.$router.replace("/");
        }
      } else {
        // 电脑端
        if (this._isMobile()) {
          this.$router.replace("/m");
        }
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.redirectMP);
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba($color: #000000, $alpha: 0.2);
  border-radius: 10px;
  -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1);
}
::-webkit-scrollbar-thumb:hover {
  background-color: rgba($color: #000000, $alpha: 0.4);
  -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1);
}
:hover:-webkit-scrollbar-thumb {
  background-color: rgba($color: #000000, $alpha: 0.2);
  border-radius: 10px;
  -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1);
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}
::-webkit-scrollbar-track:hover {
  background-color: rgba($color: #000000, $alpha: 0.01);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
}
#app {
  font-family: 微软雅黑;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-size: 16px;
}

@media (min-width: 1650px) {
  html {
    font-size: 100px;
  }
}
@media (max-width: 1650px) and (min-width: 1440px) {
  html {
    font-size: 87px;
  }
}
@media (max-width: 1440px) and (min-width: 1024px) {
  html {
    font-size: 60px;
  }
}
@media (max-width: 1024px) and (min-width: 750px) {
  html {
    font-size: 6.94vw;
  }
}
@media (max-width: 750px) {
  ::-webkit-scrollbar {
    width: 5px;
  }
  html {
    font-size: 13.34vw;
  }
}
</style>
