import Vue from "vue";
import VueRouter from "vue-router";
import homePage from "../views/homePgae.vue";
import mHome from "../views/m/mHome.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "首页",
    component: homePage,
  },
  {
    path: "/m",
    name: "移动端首页",
    component: mHome,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
