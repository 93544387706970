<template>
  <div class="backToTop" v-show="visible" @click="toTop">
    <!-- <i class="el-icon-caret-top"></i> -->
    <div class="in">
      <div class="top"></div>
      <div class="text">TOP</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    toTop() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.backToTop {
  position: fixed;
  right: 40px;
  bottom: 40px;
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #409eff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  z-index: 5;
  animation: fadeIn 0.5s linear;

  &:hover {
    background-color: #f2f6fc;
  }

  .in {
    text-align: center;
  }
  .top {
    width: 0;
    height: 0;
    margin: -3px auto 0;
    border-width: 6px 6px;
    border-style: solid;
    border-color: transparent transparent #409eff transparent;
  }
  .text {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .backToTop {
    right: 7px;
    bottom: 7px;
  }
}
</style>
