import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

import { ActionSheet } from "vant";
Vue.use(ActionSheet);

import "animate.css";
import "wowjs/css/libs/animate.css";
import wow from "wowjs";
Vue.prototype.$wow = wow;

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const localLang = localStorage.getItem("lang");
const defaultLang = navigator.language;
let lang = "";
if (localLang) {
  // 优先local语言
  lang = localLang;
} else {
  // 其次设备浏览器语言
  const langs = ["zh", "ja", "en"]; // 可替换的浏览器语言
  lang = langs.find((ele) => defaultLang.includes(ele));
}
const i18n = new VueI18n({
  locale: lang || "zh", //  默认中文
  messages: {
    zh: require("./plugin/langs/zh"), // 中文语言包
    ja: require("./plugin/langs/ja"), // 日文语言包
    en: require("./plugin/langs/en"), // 日文语言包
  },
});

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
