export const message = {
  lang: "ja",
  action: {
    cancel: "キャンセル",
  },
  sideMap: {
    map1: "ホームページ",
    map2: "入る盤古縦横",
    map3: "グループ概要",
    map4: "実力の証明",
    map5: "ビジネスマップ",
    map6: "企業文化",
    map7: "パートナー",
    map8: "お問い合わせ",
  },
  banner: {
    title: "ビジネスサービス領域に注力し",
    sub: "企業の独自経営と投資育成を統合することを目指しています",
  },
  box: {
    introTitle: "入る盤古縦横",
    introDesc:
      "成都盤古縦横グループは、2003年に創業された成都盤古ネットワーク有限会社から派生しました。ビジネスサービスに専念し、企業の運用管理と投資孵化などのサービスを統合的に提供しています。",
    introMore: "もっと知りたい",
    viewAll: "すべて表示",
    generalTitle: "グループ概要",
    generalDesc:
      "成都盤古縦横グループは、2003年に創業た成都盤古ネットワーク有限会社から派生しました。ビジネスサービスに専念し、企業の運用管理と投資孵化などのサービスを統合的に提供しています。当グループは、四川、重慶、山西、甘粛、広西、北京、日本などの地域/国に支社を持っています。広告サービス、技術支援、金融質屋業サービス、宿泊及び越境旅行サービスは主な業務として展開しています。盤古広告、盤古情報サービス、福都典当、日本pangooホテル、盤古縦横国際旅行などの業務とサービスブラントを孵化しました。創業21年以来、当グループは成都市人民政府、成都市人材サービスセンター、成都市市場監督管理局、成都市気象局、西南ガス田会社、中国五冶グループ会社、成都市ソフトウェア産業発展センターなどに広告と技術サービスを提供しました。中国工程物理研究院、綿陽南山中学校、成都京東方病院などの企業・事業体にコンテンツサービスを提供し、中国銀行、民生銀行、郵貯銀行などに長期的にブランド宣伝サービスを提供しました。多くの中小企業に短期緊急金融サービスを提供しました。そして2022年から日本の民泊運営管理、国内旅行/海外旅行、企業/団体のカスタマイズ旅行、移民留学などのサービスを展開し、業界やお客様に高い評判と知名度を蓄積している。",
    unit: "とし",
    strengthTitle: "実力の証明",
    strength1Label: "業務経験",
    strength1Desc:
      "腾讯、百度、小红书、超级汇川、巨量引擎などのオンライン広告サービスや、分众、门禁道闸、随心推などのオフライン広告サービス、技術サービス、コンテンツサービス、金融質屋サービス、酒旅サービスなどの業務",
    strength2Label: "サービスチーム",
    strength2Desc:
      "440人以上のカスタマーサービスチームは、多くのお客様に専門的なインターネット広告および技術サービス、コンテンツサービス、質屋業サービス、宿泊及び旅行サービスを提供しています。",
    strength3Label: "一級オフィスビル",
    strength3Desc:
      "四川省、重慶市、甘粛省、山西省、広西チワン族自治区、北京市、東京（日本）の都心部に一流のオフィススペースを構えています。",
    strength4Label: "協力顧客",
    strength4Desc:
      "政府、教育、エネルギー、製造、小売り、電子商取引、医療、飲食、物流、観光、写真、デザイン、家具、美容、化学など、幅広い業界に関わっており、広く分布しています",
    cooperationTitle: "パートナー",
  },
  business: {
    title: "ビジネスマップ",
    more: "もっと知りたい",
    pangu: {
      title: "広告事業",
      info1: "検索広告",
      info2: "情報フィード広告",
      info3: "オフライン広告",
      info4: "電子商取引サービス",
      info5: "技術サービス",
      info6: "コンテンツサービス",
      info7: "品牌サービス",
      desc: "盤古広告は、インターネット広告および技術の専門サービスプロバイダーです。2003年の設立以来、多様なメディアプラットフォームリソース、強力な直販管理能力、豊富な業界経験、良質なサービス意識と能力を基に、盤古広告は、多種多様なインターネットメディアと地域のさまざまな業界の顧客を繋げるサービスプラットフォームを構築することを目指しています。さまざまなインターネットメディアプラットフォームの広告を地域市場で展開し、伝統的なビジネスモデルとネットワークマーケティングを効果的に組み合わせて、新しい競争環境で独自の優位性を得てROIを向上させるお手伝いをしてきました。盤古広告は多年にわたり、顧客と業界で良好な評判と知名度を築き上げています。",
    },
    fangfa: {
      title: "技術サービス",
      info1: "大口顧客のデジタルカスタマイズ",
      info2: "街区/コミュニティSaaSサービス",
      info3: "田舎の振興IoTサービスプロバイダー",
      desc: "方法デジタルは、革新的なデジタル技術サービスプロバイダーです。2003年に設立され、企業や組織に対して専門のソフトウェア開発サービス、システム統合サービス、電子商取引サービスを提供しています。教育、エネルギー、製造、政府、小売り、電子商取引などの重要な領域に重点を置き、専門的かつ包括的かつ効率的なソフトウェア技術サービスを提供し続けています。方法デジタルは、ソフトウェア企業認定証明書とソフトウェア製品登録証明書を取得した双ソフト認定企業であり、ISO9001品質管理システム認証、ISO14001環境管理システム認証、OHSAS18001職業健康安全管理システム認証を取得した企業であり、電信付加価値サービス営業許可証（ICP）を取得した専門サービスプロバイダーであり、四川省電子商取引協会および成都ソフトウェア業協会の常務理事会員でもあります。",
    },
    diandang: {
      title: "金融サービス",
      info1: "不動産担保ローン",
      info2: "財産権担保ローン",
      info3: "動産担保ローンなど",
      desc: '四川福都典当有限公司は2009年に設立され、登録資本8000万元で、国家金融監督管理総局四川監督局の承認を受けて「典当経営許可証」を取得した非銀行系金融機関で、各種の担保、抵当ローン業務を展開しています。主な業務には、動産担保典当、財産権担保典当、不動産担保典当などがあります。当社は「誠実を基に、顧客第一」の経営理念を持ち、"サービス第一、誠実に立つ、人々の急を緩める"というサービス方針を奉じ、"敬業、守信、忠誠、奉仕、開拓、創新、志を立て、強くなる"という企業精神を持ち、"柔軟で便利、短期間"の融資サービスを広大な顧客に提供し、福都典当を中小企業や一般の人々が信頼できる"融資銀行"に育てることに取り組んでいます。',
    },
    hongjun: {
      title: "品牌サービス",
      info1: "ニューメディアの運営",
      info2: "平面デザイン",
      info3: "動画制作",
      info4: "イベント企画",
      info5: "ソフトウェア開発",
      desc: "盤古広告は、政府および企業の顧客がブランドマネジメント、イメージプランニング、広告コンサルティング、PR活動を行うのを支援することに専念し、広告デザイン、制作、クロスメディアプレイスメント、イベント実行、ソフトウェア開発などのサービスを提供しています。",
      desc_back:
        "成都鸿钧品牌管理有限公司は、政府および企業の顧客がブランドマネジメント、イメージプランニング、広告コンサルティング、PR活動を行うのを支援することに専念し、広告デザイン、制作、クロスメディアプレイスメント、イベント実行、ソフトウェア開発などのサービスを提供しています。",
    },
    riben: {
      title: "国際業務",
      info1: "民泊運営",
      info2: "ホテル管理",
      info3: "日本滞在旅行",
      desc: "盤古日本株式会社は、2020年に日本の東京で設立されました。中国から日本へ、日本から中国へ、クロスボーダーの観光や日本への投資などのサービスに特化しています。東京都市圏を中心に、日本全国にサービスを提供し、優れた物件を見つけ出し、関東地域の民泊ホテルのブランドリーダーを築いています。日本での旅行者には上品で快適な宿泊施設を提供し、高純資産の人々には理想的な投資機会を提供し、クロスボーダーのビジネスクライアントには高品質なコンサルティングサービスを提供しています。",
    },
    huanzong: {
      title: "観光サービス",
      info1: "国内旅行/海外旅行",
      info2: "企業/団体向けのカスタムツアー",
      info3: "グローバルビザ代行",
      info4: "航空券とホテル、レンタカーの代行予約",
      info5: "移民と留学サービス",
      desc: "成都寰宇縦横国際旅行社有限公司（寰纵国旅と略されます）は2019年に設立され、本社は四川省成都市にあり、路線のカスタマイズ、攻略サービス、グローバルビザ代行、ホテルと航空券の予約、移民留学などのサービスを提供するフルチェーン総合観光サービスプロバイダです。業務範囲は全国に及び、個人や団体旅行、企業のビジネス訪問、会議などの関連業務を受け付けることができます。",
    },
    hrefTip: "公式サイトに入る",
    noHrefTip: "お楽しみに",
  },
  mission: {
    mission1Title: "ミッション",
    mission1desc1: "お客様の事業発展を支援し、",
    mission1desc2: "従業員の人生の価値を実現する",
    mission2Title: "ビジョン",
    mission2desc1: "卓越を追求する総合的な商業サービス企業である",
    mission2desc2: "社会的責任を果たし、人情味のある企業である",
    mission3Title: "価値観",
    mission3desc1: "正直と誠実 勤勉と協力",
    mission3desc2: "変化を受け入れること 命令に従い",
    mission3desc3: "第一になること 使命を果たすこと",
  },
  contact: {
    title: "お問い合わせ",
    addrTitle: "住所",
    addrDesc: "四川省成都市東大路318号IMP環球都汇広場37F",
    phoneTitle: "電話",
    emailTitle: "メールアドレス",
  },
  bottom: {
    copyText: "©2024 成都盤古縦横グループは 著作権所有",
    icp: "ファイル番号",
  },
};
