<template>
  <img :src="src" alt="滑动提示" :class="['mouse', theme]" />
</template>

<script>
export default {
  props: {
    theme: {
      type: String,
      default: "",
    },
  },
  computed: {
    src() {
      if (this.theme === "") {
        return "https://www.pangodgroup.com/static/mouse.png";
      } else {
        return `https://www.pangodgroup.com/static/mouse-${this.theme}.png`;
      }
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@keyframes mouseMove {
  20% {
    transform: translate(-50%, -5px);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, 20px);
    opacity: 0;
  }
}
.mouse {
  width: 300px;
  opacity: 1;
  position: absolute;
  left: 50%;
  bottom: 5%;
  z-index: 20;
  transform: translate(-50%, 0);
  animation: mouseMove 2s infinite;
}
.black {
  width: 38px;
}

@media (max-width: 1440px) {
  .mouse {
    width: 250px;
  }
  .black {
    width: 31px;
  }
}
</style>
